<template>
	<div class="p-grid">
		<div class="p-col-12">
			<div class="card">

        <Toolbar class="p-mb-4">
          <template #start>
            <div style="font-size: 1.4em; color: inherit" class="p-mr-3">{{ $t('Services') }}</div>
            <AddNewButton :disabled="!allDataLoaded" :label="$t('Add')" @click="addNewItem" class="p-mr-2"/>
            <AddNewButton :label="$t('Add category')" @click="addNewCategory" class="p-mr-2"/>
            <Button @click="actualizeSorting" class="p-button-secondary p-button-raised" icon="pi pi-refresh" label="Actualize sorting"></Button>
          </template>
          <template #end>
            <SearchInput :disabled="dataTableIsLoading" @change-processed-search-value="changeProcessedSearchValue" v-model="searchData" @show-spinner="isLoading = true"/>
          </template>
        </Toolbar>


        <!--  Desktop DataTable -->
        <div class="p-d-flex">
          <div style="width: 20%; min-width: 300px;" class="p-mr-4">
<!--            <div>-->
<!--              <Button v-if="!keysAreExpanded" type="button" icon="pi pi-plus" label="Expand All" @click="expandAll" />-->
<!--              <Button v-else-if="keysAreExpanded" type="button" icon="pi pi-minus" label="Collapse All" @click="collapseAll" />-->
<!--            </div>-->
            <Tree @node-select="changeCategory" :value="categoriesList" :expandedKeys="expandedKeys" selectionMode="single" v-model:selectionKeys="selectedCategory"></Tree>
          </div>

          <div style="width: 80%">
            <Spinner v-if="isLoading"></Spinner>
<!--            <DesktopDataTable v-else-if="!isLoading && !$store.state.tabletLayout"-->
            <DesktopDataTable v-else
                              :showHeader="false"
                              :tableName="$t('Services')"
                              :dataTableIsLoading="dataTableIsLoading"
                              :itemsArrayIsEmpty="itemsArrayIsEmpty"
                              :sort="sort"
                              :showSearchInput="false"
                              :actionButtonsCount="actionButtonsCount"
                              @sort-items="sortItems"
                              :headers="[
              // {name: 'code', title: 'Code', width: '15%', sortable: true},
              {name: 'name', title: 'Name', width: '30%', sortable: true},
              {name: 'time', title: 'Time', sortable: true},
              {name: 'sell', title: 'Sell', sortable: true},
              // {name: 'area', title: 'Area', sortable: true},
              {name: 'use_consumables', title: 'Use consumables', sortable: true},
              {name: 'recurring', title: 'Recurring', sortable: true},
              // {name: 'created_at', title: $t('Created'), sortable: true},
          ]">
              <template v-slot:body>
                <tr v-for="item of items"
                    :key="item.id"
                    :class="{'updated-item': isItemUpdated(item.id)}">
<!--                  <td>-->
<!--                    <span v-if="item.code">{{ item.code }}</span>-->
<!--                  </td>-->
                  <td>
                    <span v-if="item.name">{{ item.name }}</span>
                    <i v-else class="ti-minus"></i>
                  </td>
                  <td>
                    <span v-if="item.time">{{ formatDecimal(item.time) + ' ' + $t('h') + '.' }}</span>
                    <i v-else class="ti-minus"></i>
                  </td>
<!--                  <td>-->
<!--                    <span v-if="item.purchase">{{ formatCurrency(+item.purchase || 0) }}</span>-->
<!--                  </td>-->
                  <td>
                    <span>{{ formatCurrency(item.sell, 2, 4) }}</span>
                  </td>
<!--                  <td>-->
<!--                    <span v-if="item.area">{{ item.area.name }}</span>-->
<!--                    <i v-else class="ti-minus"></i>-->
<!--                  </td>-->
                  <td>
                    <span>{{ item.use_consumables ? $t('Yes') : $t('No') }}</span>
                  </td>
                  <td>
                    <span>{{ item.is_recurring ? $t('Yes') : $t('No') }}</span>
                  </td>
                  <td>
                    <div class="p-d-flex">
                      <EditButton :disabled="!allDataLoaded || dataTableIsLoading"
                                  @click="editItem(item)" />
                      <DeleteButton @click="confirmItemDelete(item)"
                                    v-show="$store.state.user.role === constants.userRoles.superAdmin || $store.state.user.role === constants.userRoles.admin"/>
                    </div>
                  </td>
                </tr>
              </template>
            </DesktopDataTable>
          </div>
        </div>

        <OwnPaginator v-show="items && items.length"
                      :rows="pagination.perPage"
                      :currentPage="pagination.currentPage - 1"
                      :totalPages="pagination.pageCount"
                      :totalRecords="pagination.totalCount"
                      :rowsPerPageOptions="[5,10,25,50,100,200]"
                      :pageLinkSize="11">
        </OwnPaginator>

        <TotalRecordsData :pagination="pagination" :itemsArrayIsEmpty="itemsArrayIsEmpty"/>

        <ServiceModal :visible="itemModal"
                      :item="item"
                      :areas="areas"
                      :categories="categories"
                      @update-items="updateItems"
                      @update-item="updateService"
                      @close="closeItemModal">
        </ServiceModal>

        <ServiceCategoryModal :visible="categoryModal"
                              :item="category"
                              @update-items="getCategories(false)"
                              @close="closeCategoryModal">
        </ServiceCategoryModal>

        <ConfirmDeleteModal
            :visible="confirmItemDeleteModal"
            @close="closeConfirmItemDeleteModal"
            @delete-item="deleteItem"
            :data="itemToDeleteData">
        </ConfirmDeleteModal>
			</div>

		</div>
	</div>
</template>

<script>
import httpClient from '@/services/http.services'
import ServiceModal from '@/pages/data_directory/components/ServiceModal'
import ServiceCategoryModal from '@/pages/data_directory/components/ServiceCategoryModal'
import datatableMixins from "@/mixins/datatableMixins";
import httpMixins from "@/mixins/httpMixins";
import formatMixins from "@/mixins/formatMixins";
import settings from "@/settings";

export default {
  mixins: [ datatableMixins, httpMixins, formatMixins ],
  components: { ServiceModal, ServiceCategoryModal },
	data() {
		return {
      taxes: null,
      selectedCategory: null,
      selectedCategoryId: null,
      expandedKeys: {},
      keysAreExpanded: false,
      categoriesList: [
        {
          key: '0', label: this.$t('All categories'), icon: 'ti-folder', children: []
        }
      ],
      categories: [],
      fieldsExpand: '',
      apiLink: 'service/get-active-services',
      routePath: '/services',
      sort: {
        column: 'name',
        direction: '',
      },
      totalServicesCount: null,
      // itemsLoadingStarted: false,
      categoryModal: false,
			category: {},
		}
	},
  watch: {
    // selectedCategory() {
    //   // console.log(this.selectedCategory)
    //   this.changeCategory()
    //   // const selectedCategoryKey = Object.keys(value)[0]
    //   // console.log(value)
    //   // if (this.categoriesList && this.categoriesList.length && this.categoriesList[0].children) {
    //   //   const selectedCategory = (this.categoriesList[0].children).filter(child => child.key === selectedCategoryKey)[0]
    //   //
    //   //   if (selectedCategory) {
    //   //     this.selectedCategoryId = selectedCategory.id
    //   //   } else {
    //   //     this.selectedCategoryId = 0
    //   //   }
    //   // }
    //   //
    //   // if (!this.itemsLoadingStarted) {
    //   //   this.getItems()
    //   // }
    //
    //
    // },
    '$i18n.locale'() {
      if (this.totalServicesCount) {
        this.categoriesList[0].label = `${this.$t('All categories')} (${this.totalServicesCount})`
      } else {
        this.categoriesList[0].label = this.$t('All categories')
      }

      const undefinedCategory = this.categoriesList[0].children.filter(child => child.id === 0)?.[0]
      if (undefinedCategory) {
        undefinedCategory.label = `${this.$t('Undefined')} (${undefinedCategory.count})`
      }

    },
    // selectedItems() {
    //   if (this.checkAllItemsAreSelected() && this.selectedItems.length) {
    //     this.selectAllCheckbox.push(0)
    //   } else {
    //     this.selectAllCheckbox = []
    //   }
    // },
    '$store.state.firstLayerIsOpened'() {
      if (!this.$store.state.firstLayerIsOpened) {
        if (this.categoryModal) {
          this.closeCategoryModal()
        }
      }
    },
  },
  mounted() {
    if (settings.autoLogoutTimeout && this.$store.state.logoutTimestampIsOverdue) return false
    // this.checkRouteQuery()
    this.getCategories()
    this.getAreas()
    // if (!this.itemsLoadingStarted) {
    //   this.getItems()
    // }

	},
  methods: {
    async actualizeSorting() {
      try {
        const { status } = await httpClient('service/set-sorting')
        if (status === 200) {
          this.$toast.add({severity:'success', detail: this.$t('Data updated'), life: settings.toastLife})
        }
      } catch (err) {
        this.showError(err)
      }
    },
    // changeCategory(node) {
    //   const selectedCategoryKey = Object.keys(this.selectedCategory)[0]
    //
    //   if (this.categoriesList && this.categoriesList.length && this.categoriesList[0].children) {
    //     const selectedCategory = (this.categoriesList[0].children).filter(child => child.key === selectedCategoryKey)[0]
    //     this.selectedCategoryId = selectedCategory ? selectedCategory.id : null
    //   }
    //
    //   // if (!this.itemsLoadingStarted) {
    //   //   this.getItems()
    //   // }
    //
    //   const route = {
    //     route: this.$route.path,
    //     query: {
    //       ...this.$route.query,
    //       category: this.selectedCategoryId,
    //     }
    //   }
    //   if (!this.selectedCategoryId) {
    //     delete route.query.category
    //   }
    //   this.$router.push(route)
    // },
    changeCategory(node) {
      // const selectedCategoryKey = Object.keys(this.selectedCategory)[0]

      if (this.categoriesList && this.categoriesList.length && this.categoriesList[0].children) {
        const selectedCategory = (this.categoriesList[0].children).filter(child => child.key === node.key)[0]
        this.selectedCategoryId = selectedCategory ? selectedCategory.id : null
        // console.log(selectedCategory)
      }

      const route = {
        route: this.$route.path,
        query: {
          ...this.$route.query,
          category: this.selectedCategoryId,
        }
      }
      if (!this.selectedCategoryId && this.selectedCategoryId !== 0) {
        delete route.query.category
      }
      this.$router.push(route)
    },
    expandAll() {
      for (let category of this.categoriesList) {
        this.expandCategory(category);
      }
      this.keysAreExpanded = true
      this.expandedKeys = {...this.expandedKeys};
    },
    collapseAll() {
      this.expandedKeys = {};
      this.keysAreExpanded = false
    },
    expandCategory(category) {
      this.expandedKeys[category.key] = true;
      if (category.children && category.children.length) {
        for (let child of category.children) {
          this.expandCategory(child);
        }
      }
    },
    async getCategories() {
      try {
        const { status, data } = await httpClient('service/get-categories?sort=sorting&expand=servicesCount')
        if (status === 200 && data) {
          const categories = data[0] ?? []
          const undefined_items_count = data[1] ?? 0
          const children = []
          let i = 0
          if (+undefined_items_count) {
            children.push({
              key: '0-' + i++,
              id: 0,
              label: `${ this.$t('Undefined') } (${undefined_items_count || 0})`,
              icon: 'ti-folder',
              count: +undefined_items_count || 0
            })
          }
          if (categories.length) {
            categories.forEach(category => {
              children.push({
                key: '0-' + i++,
                id: category.id,
                label: `${category.name} (${category.servicesCount || 0})`,
                icon: 'ti-folder',
                count: +category.servicesCount || 0
              })
            })
            this.categories = categories
          }

          if (children && children.length) {
            this.totalServicesCount = children.reduce((sum, child) => sum + child.count, 0)
            this.categoriesList[0].label = `${this.$t('All categories')} (${this.totalServicesCount})`
            this.categoriesList[0].children = children
            this.expandAll()
          }
        }
      } catch(err) {
        this.showError(err)
      } finally {
        this.checkRouteQuery()
        this.getItems()
      }
      // if (changeCategory) {
      //   this.selectedCategory = { 0: true }
      // }
    },
    // updateItems(service, clearItem = false) {
    //   // if (service) {
    //   //   this.updatedItemId = service.id
    //   // }
    //   // if (clearItem) {
    //   //   this.item = {}
    //   //   this.submitted = false
    //   // }
    //   // // this.getItems()
    //   this.updateItems()
    //
    //   if (service.isNew) {
    //     this.getCategories(true)
    //   } else {
    //     this.getCategories(false)
    //   }
    // },
    updateItems(itemId, getItems = true) {
      if (itemId) {
        this.updatedItemId = itemId
      }

      if (getItems) {
        this.getCategories()
      }

      this.$emit('update-items') // Important: this is for update items in parent TAB component
    },
    updateService(service) {
        if (service.isNew || service.categoryWasChanged) {
          this.getCategories()
        } else {
          this.getItems()
        }
    },
    // checkRouteQuery() {
    //   if (this.$route.query.page) {
    //     this.pagination.currentPage = +this.$route.query.page
    //   } else {
    //     this.pagination.currentPage = 1
    //   }
    //   if (this.$route.query.perPage) {
    //     this.pagination.perPage = +this.$route.query.perPage
    //   } else {
    //     this.pagination.perPage = 25
    //   }
    //   if (this.$route.query.sort) {
    //     const firstSign = this.$route.query.sort[0]
    //     if (firstSign === '-') {
    //       this.sort.direction = '-'
    //       let sortColumn = this.$route.query.sort.split('')
    //       this.sort.column = sortColumn.splice(1).join('')
    //     } else {
    //       this.sort.direction = ''
    //       this.sort.column = this.$route.query.sort
    //     }
    //   }
    //
    //   // if (this.$route.query.category && this.$route.query.category !== '0') {
    //   if (this.$route.query.category) {
    //     this.selectedCategoryId = +this.$route.query.category
    //     const key = this.categoriesList[0].children.find(child => child.id === +this.$route.query.category)?.key
    //     if (key)  {
    //       const obj = {}
    //       obj[key] = true
    //       this.selectedCategory = {...obj}
    //     } else {
    //       this.selectedCategory = { 0: true }
    //       this.selectedCategoryId = null
    //     }
    //   } else {
    //     this.selectedCategory = { 0: true }
    //     this.selectedCategoryId = null
    //   }
    //
    //   if (this.$route.query.search) {
    //     this.searchData = this.$route.query.search
    //   } else {
    //     this.searchData = ''
    //   }
    // },
    async getItems() {
      this.dataTableIsLoading = true
      this.disableEditButton = true
      // this.itemsLoadingStarted = true
      try {
        // const { status, data, headers } = await httpClient(`${this.apiLink}?categoryId=${this.selectedCategoryId}&page=${this.pagination.currentPage}&per-page=${this.pagination.perPage}&sort=${this.sort.direction + this.sort.column}&search=${this.searchData}&expand=${this.fieldsExpand}`)
        const { status, data, headers } = await httpClient({
          url: this.apiLink,
          params: {
            categoryId: this.selectedCategoryId,
            page: this.pagination.currentPage,
            'per-page': this.pagination.perPage,
            sort: this.sort.direction + this.sort.column,
            search: this.processedSearchValue ?? null,
            expand: this.fieldsExpand
          }
        })
        if (status === 200 && data) {
          this.pagination.totalCount = +headers['x-pagination-total-count']
          this.pagination.pageCount = +headers['x-pagination-page-count']
          // this.pagination.currentPage = +headers['x-pagination-current-page']
          // this.pagination.perPage = +headers['x-pagination-per-page']
          this.items = data

          if (!this.items?.length) {
            this.pagination.pageCount = 1
            // this.pagination.currentPage = 1
          }
        }
      } catch(err) {
        this.showError(err)
      } finally {
        this.isLoading = false
        this.disableEditButton = false
        this.dataTableIsLoading = false
      }
    },
    closeCategoryModal() {
      this.categoryModal = false
      this.$store.commit('toggleFirstLayer', false)
      this.submitted = false
    },
    addNewCategory() {
      this.category = {}
      this.submitted = false
      this.categoryModal = true
      this.$store.commit('toggleFirstLayer', true)
    },
    async deleteItem() {
      console.log('delete service')
      // try {
      //   const { status } = await httpClient.post('user/delete-user', {id: this.itemToDelete.id})
      //   if (status === 204) {
      //     this.updatedItemId = null
      //
      //     this.searchData = ''
      //     this.$router.push({
      //       route: this.$route.path,
      //       query: {
      //         ...this.$route.query,
      //         search: '',
      //       }
      //     })
      //
      //     this.getItems()
      //     this.closeConfirmItemDeleteModal()
      //   }
      // } catch(err) {
      //   this.showError(err)
      // }
    },
	},
  computed: {
    allDataLoaded() {
      return !!(this.areas)
    },
    actionButtonsCount() {
      if (this.$store.state.user.role === this.constants.userRoles.superAdmin || this.$store.state.user.role === this.constants.userRoles.admin) {
        return 2
      } else {
        return 1
      }
    },
    itemToDeleteData() {
      return 'delete data'
    },
  }
}
</script>

<style scoped lang="scss">

</style>